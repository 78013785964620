.presetsLabel {
  border: var(--select-border);
  border-radius: var(--select-border-radius);
  padding: var(--select-padding);
  font-size: var(--select-font-size);
  color: var(--select-color);
  font-weight: 800;
  font-family: var(--select-font-family);
  cursor: pointer;
  position: relative;
  width: 15rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
  overflow: hidden;
  max-height: 4rem;

  &.open {
    border-color: var(--select-focus-color);
  }

  .icon {
    fill: var(--select-icon-color);
    margin-left: 1rem;
    transition: fill 200ms, transform 200ms;
    flex-shrink: 0;

    &.open {
      transform: rotate(180deg);
      fill: var(--select-focus-color);
    }
  }
}

.presetsModal {
  position: absolute;
  z-index: 1000;
  width: 20rem;
  color: var(--select-color);

  .searchBar {
    padding: 1.5rem;
  }

  .presetsModalLabel {
    padding: var(--item-padding);
    border-bottom: 1px solid var(--item-border-color);
  }

  .presetsList {
    max-height: 20rem;
    overflow-y: scroll;

    .presetRow {
      border-bottom: 1px solid var(--item-border-color);
      cursor: pointer;
      padding: 1rem 1.6rem;
      font-size: var(--item-label-font-size);
      font-family: var(--item-label-font-family);
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .presetRowName {
        cursor: pointer;
        max-width: 73rem;
        overflow-x: hidden;
      }

      .presetRowName:hover {
        text-decoration: underline;
      }

      .links {
        display: flex;
        align-items: center;

        svg > use {
          fill: var(--reporting-preset-modal-icon-color);
        }

        button + button {
          margin-left: 1rem;
        }
      }
    }
  }

  .actions {
    margin: 1rem;
    display: flex;
    justify-content: center;
  }
}

.timeframe {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2.8rem;
  padding-left: 2rem;
  justify-content: space-between;

  .pickerTo {
    margin-left: 1.2rem;
  }

  .date {
    width: 13.0rem !important;
    min-width: auto;
    margin-right: 1.2rem;
  }

  .hour {
    width: 10.0rem !important;
  }

  .timezone, .period {
    margin-left: 5.2rem;
    min-width: 16rem;
  }
}

.timeframe + .timeframe {
  margin-top: 1.2rem;
}
